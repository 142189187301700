import React from "react";

function Hero3d() {
    return <>
        <model-viewer
            alt="Box of NSlim"
            src="/hero.glb"
            shadow-intensity="1"
            camera-controls
            auto-rotate
            auto-rotate-delay="0"
            camera-orbit="deg 90deg 50m"
            camera-target="0m 0m 0m"
            disable-pan
            field-of-view="40deg"
            disable-zoom
            loading="lazy"
            width="100%">
        </model-viewer>
    </>
}

export default Hero3d;