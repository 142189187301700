import "./NowSlim.scss";
import '@fontsource/inter/700.css';
import '@fontsource/inter/400.css'
import Logo from './Logo.png';
import {Box, Image} from "@chakra-ui/react";

function NowSlimTemplate(props) {
    return (
        <div className={"page nslim"}>
            <Box pt={[1,5]} pb={[0,2]} textAlign={'center'}>
                <Image src={Logo} height={[5,10]} mt={2} mb={2} display={'inline-block'} />
            </Box>

            {props.children}
        </div>
    );
}

export default NowSlimTemplate;
